.App {
    min-height: 100vh;
    display: flex;
    font-weight: 400;
    font-family: sans-serif;
}

.navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
    margin: 0em auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.inner {
    width: 450px;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
}

.outer .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
}

.outer h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
}

.custom-control-label {
    font-weight: 400;
}

.logo {
    width: 40%;
}

.login-wrapper {
    justify-content: center;
    text-align: center;
    margin-bottom: 1em;
}
