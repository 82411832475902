.file-drop {
    border: 1px dashed gray;
	height: 10rem;
	width: 100%;
	padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
	text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &.active {
        border: 2px solid var(--bs-primary);
    }
}

.JobEditPage textarea {
    min-height: 10rem;
}