.TranslationRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .body {
        width: 100%;
        max-width: 990px;
        padding: 1em;
    }
}