.status-steps {
    display: flex;
    flex-direction: row;
    width: 480px;
    align-items: center;
    padding-top: 50px;

    > .step {
        position: relative;

        .label {
            position: absolute;
            width: 100px;
            top: -40px;
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
            font-size: 1.15em;
            font-weight: bold;
        }

        .step-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: white;
            border: 1px solid lightgrey;
            border-radius: 50%;
            color: lightgrey;

            &.active {
                background-color: rgb(40, 212, 104);
                color: white;
            }

            &.stage2.active {
                background-color: rgb(250, 224, 65);
                color: black;
            }
        }
    }

    > .line {
        border-top: 1px solid lightgrey;
        flex: 1;
        height: 0;
    }
}